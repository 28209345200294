<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 0C2.20435 0 1.44129 0.31607 0.87868 0.87868C0.31607 1.44129 0 2.20435 0 3L0 13C0 13.7956 0.31607 14.5587 0.87868 15.1213C1.44129 15.6839 2.20435 16 3 16H13C13.7956 16 14.5587 15.6839 15.1213 15.1213C15.6839 14.5587 16 13.7956 16 13V3C16 2.20435 15.6839 1.44129 15.1213 0.87868C14.5587 0.31607 13.7956 0 13 0L3 0ZM5.5 4C5.5 4.39782 5.34196 4.77936 5.06066 5.06066C4.77936 5.34196 4.39782 5.5 4 5.5C3.60218 5.5 3.22064 5.34196 2.93934 5.06066C2.65804 4.77936 2.5 4.39782 2.5 4C2.5 3.60218 2.65804 3.22064 2.93934 2.93934C3.22064 2.65804 3.60218 2.5 4 2.5C4.39782 2.5 4.77936 2.65804 5.06066 2.93934C5.34196 3.22064 5.5 3.60218 5.5 4V4ZM13.5 4C13.5 4.39782 13.342 4.77936 13.0607 5.06066C12.7794 5.34196 12.3978 5.5 12 5.5C11.6022 5.5 11.2206 5.34196 10.9393 5.06066C10.658 4.77936 10.5 4.39782 10.5 4C10.5 3.60218 10.658 3.22064 10.9393 2.93934C11.2206 2.65804 11.6022 2.5 12 2.5C12.3978 2.5 12.7794 2.65804 13.0607 2.93934C13.342 3.22064 13.5 3.60218 13.5 4V4ZM12 13.5C11.6022 13.5 11.2206 13.342 10.9393 13.0607C10.658 12.7794 10.5 12.3978 10.5 12C10.5 11.6022 10.658 11.2206 10.9393 10.9393C11.2206 10.658 11.6022 10.5 12 10.5C12.3978 10.5 12.7794 10.658 13.0607 10.9393C13.342 11.2206 13.5 11.6022 13.5 12C13.5 12.3978 13.342 12.7794 13.0607 13.0607C12.7794 13.342 12.3978 13.5 12 13.5ZM5.5 12C5.5 12.3978 5.34196 12.7794 5.06066 13.0607C4.77936 13.342 4.39782 13.5 4 13.5C3.60218 13.5 3.22064 13.342 2.93934 13.0607C2.65804 12.7794 2.5 12.3978 2.5 12C2.5 11.6022 2.65804 11.2206 2.93934 10.9393C3.22064 10.658 3.60218 10.5 4 10.5C4.39782 10.5 4.77936 10.658 5.06066 10.9393C5.34196 11.2206 5.5 11.6022 5.5 12ZM8 9.5C7.60218 9.5 7.22064 9.34196 6.93934 9.06066C6.65804 8.77936 6.5 8.39782 6.5 8C6.5 7.60218 6.65804 7.22064 6.93934 6.93934C7.22064 6.65804 7.60218 6.5 8 6.5C8.39782 6.5 8.77936 6.65804 9.06066 6.93934C9.34196 7.22064 9.5 7.60218 9.5 8C9.5 8.39782 9.34196 8.77936 9.06066 9.06066C8.77936 9.34196 8.39782 9.5 8 9.5Z" fill="black"></path>
  </svg>
</template>

<script>
export default {
  name: 'DiceIcon'
}
</script>
