<template>
  <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.1873 13.7949H10.7401V9.0625C10.7401 8.97656 10.6697 8.90625 10.5838 8.90625H9.41194C9.326 8.90625 9.25569 8.97656 9.25569 9.0625V13.7949H7.81233C7.68147 13.7949 7.6092 13.9453 7.68928 14.0469L9.87678 16.8145C9.8914 16.8331 9.91007 16.8482 9.93139 16.8586C9.95271 16.869 9.97611 16.8744 9.99983 16.8744C10.0235 16.8744 10.0469 16.869 10.0683 16.8586C10.0896 16.8482 10.1083 16.8331 10.1229 16.8145L12.3104 14.0469C12.3905 13.9453 12.3182 13.7949 12.1873 13.7949V13.7949Z" fill="#003366"></path>
    <path d="M15.8477 7.16211C14.9531 4.80273 12.6738 3.125 10.0039 3.125C7.33398 3.125 5.05469 4.80078 4.16016 7.16016C2.48633 7.59961 1.25 9.125 1.25 10.9375C1.25 13.0957 2.99805 14.8438 5.1543 14.8438H5.9375C6.02344 14.8438 6.09375 14.7734 6.09375 14.6875V13.5156C6.09375 13.4297 6.02344 13.3594 5.9375 13.3594H5.1543C4.49609 13.3594 3.87695 13.0977 3.41602 12.623C2.95703 12.1504 2.71289 11.5137 2.73438 10.8535C2.75195 10.3379 2.92773 9.85352 3.24609 9.44531C3.57227 9.0293 4.0293 8.72656 4.53711 8.5918L5.27734 8.39844L5.54883 7.68359C5.7168 7.23828 5.95117 6.82227 6.24609 6.44531C6.53725 6.07169 6.88214 5.74326 7.26953 5.4707C8.07227 4.90625 9.01758 4.60742 10.0039 4.60742C10.9902 4.60742 11.9355 4.90625 12.7383 5.4707C13.127 5.74414 13.4707 6.07227 13.7617 6.44531C14.0566 6.82227 14.291 7.24023 14.459 7.68359L14.7285 8.39648L15.4668 8.5918C16.5254 8.87695 17.2656 9.83984 17.2656 10.9375C17.2656 11.584 17.0137 12.1934 16.5566 12.6504C16.3325 12.8758 16.0659 13.0546 15.7722 13.1763C15.4785 13.298 15.1636 13.3602 14.8457 13.3594H14.0625C13.9766 13.3594 13.9062 13.4297 13.9062 13.5156V14.6875C13.9062 14.7734 13.9766 14.8438 14.0625 14.8438H14.8457C17.002 14.8438 18.75 13.0957 18.75 10.9375C18.75 9.12695 17.5176 7.60352 15.8477 7.16211Z" fill="#003366"></path>
  </svg>
</template>

<script>
export default {
  name: 'DownloadIcon'
}
</script>
