<template>
  <div class="pf">
    <input class="pf__input" :type="showPassword ? 'text' : 'password'" :title="title" :placeholder="placeholder" :value="value" @input="$emit('input', $event.target.value)">
    <button type="button" class="pf__eye-button" :class="{ active: showPassword }" @click="showPassword = !showPassword">
      <EyeCloseIcon/>
      <EyeIcon/>
    </button>
    <button type="button" class="pf__generate-button" v-if="generator" @click="generatePassword">
      <DiceIcon/>
    </button>
    <div :class="`pf__strength ${strength}`" v-if="meter">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</template>

<script>
import EyeIcon from 'components/svg/Eye.vue'
import EyeCloseIcon from 'components/svg/EyeClose.vue'
import DiceIcon from 'components/svg/Dice.vue'
import generator from 'generate-password'
import passwordStrength from 'check-password-strength'

export default {
  name: 'PasswordField',
  props: {
    value: {
      type: String
    },
    title: {
      type: String
    },
    placeholder: {
      type: String
    },
    generator: {
      type: Boolean
    },
    meter: {
      type: Boolean
    }
  },
  data () {
    return {
      showPassword: false
    }
  },
  computed: {
    strength () {
      if (this.value) {
        return 's' + passwordStrength(this.value).id
      } else {
        return ''
      }
    }
  },
  methods: {
    generatePassword () {
      this.showPassword = true
      const password = generator.generate({
        length: 16,
        excludeSimilarCharacters: true,
        strict: true,
        numbers: true
      })
      this.$emit('generate', password)
      this.$emit('input', password)
    }
  },
  components: { DiceIcon, EyeCloseIcon, EyeIcon }
}
</script>
