<template>
  <div class="main main--internal">
    <template v-if="user">
      <template v-if="systemName">
        <div class="page-header__container">
          <h1 class="page-header">{{ user.surname }} {{ user.name }} {{ user.patronymic }}</h1>
          <button
            class="page-header__edit"
            type="button"
            v-tooltip="'Редактировать Ф.И.О.'"
            @click="changeNameModal = true"
          >
            <EditIcon/>
          </button>
        </div>
        <div class="fines__list__container">
          <ul class="fines__list">
            <li>
              <span>E-mail:</span>
              <span>{{ user.email }}</span>
            </li>
            <li>
              <span>Пароль:</span>
              <a href="#" @click.prevent="changePasswordModal = true" v-if="systemPassword">Сменить пароль</a>
              <div class="tooltip__container" v-else>
                <a href="#" @click.prevent="changePasswordModal = true">Сменить пароль</a>
                <i
                  class="icon icon--danger"
                  v-tooltip="'Ваш пароль сгенерирован автоматически, рекомендуется использовать свой пароль'"
                >
                  <TooltipIcon/>
                </i>
              </div>
            </li>
          </ul>
        </div>
        <div class="fines__table__container">
          <h2>Список заявок</h2>
          <div class="fines__table__wrapper">
            <VueGoodTable
              :columns="columns"
              :pagination-options="{enabled: false}"
              :rows="user.claims"
              :select-options="{ enabled: false }"
              :sort-options="{ enabled: false }"
              compactMode
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'type_id'">{{ props.row.type.title }}</span>
                <span v-if="props.column.field === 'document'">
                  <a :href="'/confirmedpay/' + props.row.uid" v-tooltip="'Скачать'">
                    <DownloadIcon/>
                  </a>
                </span>
                <span v-if="props.column.field === 'date'">{{ props.row.created_at | formatDate }}</span>
              </template>
              <div slot="emptystate">
                <span>Данных нет</span>
              </div>
            </VueGoodTable>
          </div>
        </div>
      </template>
      <div class="modal__login" :class="{ modal: systemName }" v-if="!systemName || changeNameModal">
        <div class="modal__background" @click="changeNameModal = false" v-if="systemName"></div>
        <form class="modal__body" @submit.prevent="setUserName">
          <a href="#" class="modal__close" @click.prevent="changeNameModal = false" v-if="systemName">
            <CloseIcon/>
          </a>
          <span class="modal__login__title" v-if="!systemName">
            Пожалуйста,<br> заполните Ваше Ф.И.О.,<br> для окончания регистрации
          </span>
          <span class="modal__login__title" v-else>Редактирование Ф.И.О.</span>
          <InputField
            :classes="'mb-12'"
            :required="true"
            :title="'Иванов'"
            :placeholder="'Фамилия'"
            v-model="userForm.surname.value"
          />
          <span class="modal__login__error" v-if="userForm.surname.message">{{ userForm.surname.message }}</span>
          <InputField
            :classes="'mb-12'"
            :required="true"
            :title="'Иван'"
            :placeholder="'Имя'"
            v-model="userForm.name.value"
          />
          <span class="modal__login__error" v-if="userForm.name.message">{{ userForm.name.message }}</span>
          <InputField
            :classes="'mb-12'"
            :required="true"
            :title="'Иванович'"
            :placeholder="'Отчество'"
            v-model="userForm.patronymic.value"
          />
          <span class="modal__login__error" v-if="userForm.patronymic.message">{{ userForm.patronymic.message }}</span>
          <div class="modal__login__row">
            <button class="modal__login__button" type="submit">
              <LoadingIndicator title="Ждите" v-if="loading"/>
              <span v-else>Сохранить</span>
            </button>
          </div>
        </form>
      </div>
      <div class="modal modal__login" v-if="changePasswordModal">
        <div class="modal__background" @click="changePasswordModal = false"></div>
        <form class="modal__body" @submit.prevent="setPassword" autocomplete="off">
          <a href="#" class="modal__close" @click.prevent="changePasswordModal = false">
            <CloseIcon/>
          </a>
          <span class="modal__login__title">Смена пароля</span>
          <PasswordField v-model="passwordForm.old_password.value" title="Старый пароль" placeholder="Старый пароль"/>
          <span class="modal__login__error" v-if="passwordForm.old_password.message">
            {{ passwordForm.old_password.message }}
          </span>
          <PasswordField
            v-model="passwordForm.password.value"
            title="Новый пароль"
            placeholder="Новый пароль"
            @generate="passwordForm.password_confirmation.value = $event" generator meter
          />
          <span class="modal__login__error" v-if="passwordForm.password.message">
            {{ passwordForm.password.message }}
          </span>
          <PasswordField
            v-model="passwordForm.password_confirmation.value"
            title="Повторите новый пароль"
            placeholder="Повторите новый пароль"
          />
          <span class="modal__login__error" v-if="passwordForm.password_confirmation.message">
            {{ passwordForm.password_confirmation.message }}
          </span>
          <div class="modal__login__row">
            <button class="modal__login__button" type="submit">
              <LoadingIndicator title="Ждите" v-if="loading"/>
              <span v-else>Сохранить</span>
            </button>
          </div>
        </form>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import { VueGoodTable } from 'vue-good-table'
import DownloadIcon from 'components/svg/Download.vue'
import TooltipIcon from 'components/svg/Tooltip.vue'
import CloseIcon from 'components/svg/Close.vue'
import PasswordField from 'components/PasswordField.vue'
import LoadingIndicator from 'components/LoadingIndicator.vue'
import EditIcon from 'components/svg/Edit.vue'
import InputField from 'components/InputField.vue'

export default {
  name: 'AccountPage',
  components: {
    EditIcon,
    LoadingIndicator,
    PasswordField,
    CloseIcon,
    TooltipIcon,
    DownloadIcon,
    VueGoodTable,
    InputField
  },
  async asyncData ({ store }) {
    if (store.state.cookies.access_token) {
      await store.dispatch('USER', store.state.cookies.access_token)
    }
  },
  data () {
    return {
      loading: false,
      showError: false,
      changePasswordModal: false,
      changeNameModal: false,
      columns: [{
        label: 'Тип',
        field: 'type_id'
      }, {
        label: 'Документ',
        field: 'document'
      }, {
        label: 'Дата',
        field: 'date'
      }],
      form: {
        email: '',
        password: ''
      },
      userForm: {
        surname: {
          value: '',
          message: ''
        },
        name: {
          value: '',
          message: ''
        },
        patronymic: {
          value: '',
          message: ''
        }
      },
      passwordForm: {
        old_password: {
          value: '',
          message: ''
        },
        password: {
          value: '',
          message: ''
        },
        password_confirmation: {
          value: '',
          message: ''
        }
      }
    }
  },
  created () {
    if (this.user) {
      this.userForm.surname.value = this.user.surname
      this.userForm.name.value = this.user.name
      this.userForm.patronymic.value = this.user.patronymic
    }
  },
  computed: {
    systemName () {
      return this.user.name_status && this.user.name_status.code === 10
    },
    systemPassword () {
      return this.user.password_status && this.user.password_status.code === 20
    },
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    login () {
      const fd = new FormData()
      fd.append('email', this.form.email)
      fd.append('password', this.form.password)
      this.$store.dispatch('LOGIN', fd).then(({ data }) => {
        // this.$cookie.set('access_token', data.access_token, { expires: data.expires_in, domain: 'sudbot.ru', path: '/', secure: true, sameSite: 'Strict' })
        this.$cookie.set('access_token', data.access_token, {
          expires: moment().add(data.expires_in, 'minutes').toDate().toUTCString(),
          path: '/',
          sameSite: 'Strict'
        })
        this.$store.state.cookies.access_token = data.access_token
        this.$router.go(0)
      }).catch(() => {
        this.showError = true
      })
    },
    resetValidation () {
      Object.keys(this.userForm).forEach(field => {
        this.userForm[field].message = null
      })
    },
    setUserName () {
      this.loading = true
      this.showError = false
      this.resetValidation()
      const fd = new FormData()
      Object.keys(this.userForm).forEach(key => {
        if (this.userForm[key].value) fd.append(key, this.userForm[key].value)
      })
      this.$store.dispatch('EDIT_USER_NAME', { data: fd, token: this.$store.state.cookies.access_token }).then(() => {
        this.$router.go(0)
      }).catch(({ response }) => {
        this.loading = false
        if (response.status === 401) {
          this.$cookie.delete('access_token')
          this.$swal('Токен авторизации устарел, войдите заново').then(() => {
            this.$router.go(0)
          })
        } else {
          this.showError = true
          // Вывод ошибок валидаций от Laravel
          Object.keys(response.data).forEach(message => {
            Object.keys(this.userForm).forEach(field => {
              if (field === message) {
                this.userForm[field].message = response.data[message][0]
              }
            })
          })
        }
      })
    },
    setPassword () {
      this.loading = true
      this.showError = false
      this.resetValidation()
      const fd = new FormData()
      Object.keys(this.passwordForm).forEach(key => {
        if (this.passwordForm[key].value) fd.append(key, this.passwordForm[key].value)
      })
      this.$store.dispatch('EDIT_PASSWORD', { data: fd, token: this.$store.state.cookies.access_token }).then(() => {
        this.$router.go(0)
      }).catch(({ response }) => {
        this.loading = false
        if (response.status === 401) {
          this.$cookie.delete('access_token')
          this.$swal('Токен авторизации устарел, войдите заново').then(() => {
            this.$router.go(0)
          })
        } else {
          this.showError = true
          // Вывод ошибок валидаций от Laravel
          Object.keys(response.data).forEach(message => {
            Object.keys(this.passwordForm).forEach(field => {
              if (field === message) {
                this.passwordForm[field].message = response.data[message][0]
              }
            })
          })
        }
      })
    }
  },
  metaInfo () {
    return this.$seo(
      'common',
      'Личный кабинет',
      '',
      '',
      'Личный кабинет',
      null,
      ''
    )
  }
}
</script>
