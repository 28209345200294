<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.9371 9.75001C8.40123 9.75001 8.84635 9.56563 9.17454 9.23744C9.50273 8.90925 9.6871 8.46413 9.6871 8.00001C9.6871 7.94876 9.68476 7.89797 9.68038 7.84782L7.78491 9.74329C7.83507 9.74766 7.8857 9.75001 7.9371 9.75001ZM13.7296 2.58688L13.0621 1.92001C13.0387 1.89658 13.0069 1.88342 12.9737 1.88342C12.9406 1.88342 12.9088 1.89658 12.8854 1.92001L11.1773 3.6286C10.235 3.14704 9.17575 2.90626 7.9996 2.90626C4.99648 2.90626 2.75585 4.47032 1.27773 7.59844C1.21831 7.72425 1.1875 7.86166 1.1875 8.00079C1.1875 8.13992 1.21831 8.27732 1.27773 8.40313C1.86835 9.64719 2.57955 10.6443 3.41132 11.3945L1.75835 13.0469C1.73493 13.0703 1.72177 13.1021 1.72177 13.1352C1.72177 13.1684 1.73493 13.2002 1.75835 13.2236L2.42538 13.8906C2.44882 13.9141 2.4806 13.9272 2.51374 13.9272C2.54688 13.9272 2.57866 13.9141 2.6021 13.8906L13.7296 2.76376C13.7412 2.75215 13.7504 2.73836 13.7567 2.72319C13.763 2.70801 13.7663 2.69174 13.7663 2.67532C13.7663 2.65889 13.763 2.64263 13.7567 2.62745C13.7504 2.61228 13.7412 2.59849 13.7296 2.58688ZM5.1871 8.00001C5.18706 7.52501 5.31005 7.05811 5.54408 6.64478C5.77812 6.23145 6.11523 5.88578 6.52256 5.64146C6.92989 5.39713 7.39357 5.26248 7.86841 5.25061C8.34325 5.23875 8.81307 5.35008 9.2321 5.57376L8.47241 6.33344C8.16604 6.23534 7.83856 6.22353 7.52591 6.2993C7.21327 6.37506 6.92753 6.53548 6.70006 6.76296C6.47258 6.99043 6.31216 7.27617 6.23639 7.58882C6.16062 7.90147 6.17244 8.22894 6.27054 8.53532L5.51085 9.29501C5.29766 8.89669 5.18644 8.45178 5.1871 8.00001V8.00001Z" fill="black"></path>
    <path d="M14.722 7.5969C14.172 6.43857 13.5174 5.49403 12.7581 4.76331L10.5059 7.01565C10.6958 7.51192 10.7379 8.05255 10.6271 8.57222C10.5163 9.09189 10.2574 9.56837 9.88172 9.94409C9.506 10.3198 9.02953 10.5787 8.50986 10.6895C7.99018 10.8002 7.44955 10.7582 6.95328 10.5683L5.04297 12.4786C5.92922 12.8887 6.91495 13.0938 8.00016 13.0938C11.0033 13.0938 13.2439 11.5297 14.722 8.40159C14.7815 8.27578 14.8123 8.13838 14.8123 7.99924C14.8123 7.86011 14.7815 7.7227 14.722 7.5969V7.5969Z" fill="black"></path>
  </svg>
</template>

<script>
export default {
  name: 'EyeCloseIcon'
}
</script>
