<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 15H3V4H9.61L10.61 3H3C2.73478 3 2.48043 3.10536 2.29289 3.29289C2.10536 3.48043 2 3.73478 2 4V15C2 15.2652 2.10536 15.5196 2.29289 15.7071C2.48043 15.8946 2.73478 16 3 16H14C14.2652 16 14.5196 15.8946 14.7071 15.7071C14.8946 15.5196 15 15.2652 15 15V7.5L14 8.5V15Z" fill="black"></path>
    <path d="M16.7652 2.92009L15.0802 1.23509C15.0054 1.16011 14.9166 1.10062 14.8188 1.06002C14.721 1.01943 14.6161 0.998535 14.5102 0.998535C14.4043 0.998535 14.2995 1.01943 14.2017 1.06002C14.1038 1.10062 14.015 1.16011 13.9402 1.23509L7.08522 8.13009L6.53022 10.5351C6.50658 10.6517 6.50906 10.772 6.5375 10.8875C6.56594 11.0031 6.61962 11.1108 6.69469 11.2031C6.76975 11.2954 6.86433 11.3699 6.97163 11.4212C7.07893 11.4726 7.19627 11.4995 7.31522 11.5001C7.3767 11.5069 7.43874 11.5069 7.50022 11.5001L9.92522 10.9651L16.7652 4.06009C16.8402 3.98531 16.8997 3.89647 16.9403 3.79866C16.9809 3.70085 17.0018 3.59599 17.0018 3.49009C17.0018 3.38419 16.9809 3.27934 16.9403 3.18152C16.8997 3.08371 16.8402 2.99487 16.7652 2.92009V2.92009ZM9.40522 10.0401L7.57522 10.4451L8.00022 8.63009L13.1602 3.43509L14.5702 4.84509L9.40522 10.0401ZM15.1352 4.28009L13.7252 2.87009L14.5002 2.08009L15.9202 3.50009L15.1352 4.28009Z" fill="black"></path>
  </svg>
</template>

<script>
export default {
  name: 'EditIcon'
}
</script>
